<template>
  <b-card-code>
    <b-alert
      v-if="loading"
      variant="primary"
      show
    >
      <div class="alert-body">
        <span><strong>Cargando Datos.</strong> Un momento...</span>
      </div>
    </b-alert>
    <b-alert
      v-if="errorMessage"
      variant="danger"
      show
    >
      <div class="alert-body">
        <span>{{ errorMessage }}</span>
      </div>
    </b-alert>
    <b-form
      v-if="!loading"
      @submit.prevent="updateHomolgacionBank()"
    >
      <b-row>
        <b-col
          cols="12"
          sm="12"
          md="12"
          xs="12"
          lg="6"
        >
          <b-form-group
            label="Banco Origen"
            label-for="status"
          >
            <v-select
              id="bankOrigin"
              v-model="item.CODIGO_BANCO_ORIGEN"
              label="title"
              :options="sourceBank"
              :reduce="option => option.value"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          md="12"
          xs="12"
          lg="6"
        >
          <b-form-group
            label="Gerencia Origen"
            label-for="gerenciaOrigen"
          >
            <v-select
              id="gerenciaOrigen"
              v-model="item.CODIGO_GERENCIA_ORIGEN"
              label="title"
              :options="sourceManagement"
              :reduce="option => option.value"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          md="12"
          xs="12"
          lg="6"
        >
          <b-form-group
            label="Código del Centro de Costo Origen"
            label-for="code"
          >
            <b-form-input
              id="code"
              v-model="item.CODIGO_CENTRO_ORIGEN"
              type="number"
              placeholder=""
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          md="12"
          xs="12"
          lg="6"
        >
          <b-form-group
            label="Cuenta Contable Origen"
            label-for="adGroup"
          >
            <b-form-input
              id="accountOrigin"
              v-model="item.CODIGO_CUENTA_ORIGEN"
              type="number"
              min="1000000000000000"
              max="9999999999999999"
              placeholder=""
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          md="12"
          xs="12"
          lg="6"
        >
          <b-form-group
            label="Banco Destino"
            label-for="status"
          >
            <v-select
              id="bankOrigin"
              v-model="item.CODIGO_BANCO"
              label="title"
              :options="targetBank"
              :reduce="option => option.value"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          md="12"
          xs="12"
          lg="6"
        >
          <b-form-group
            label="Gerencia Destino"
            label-for="status"
          >
            <v-select
              id="ManagementDest"
              v-model="selectedManagementDest"
              label="gerencia"
              :options="gerenciasDirectivas"
              :reduce="option => option.idGerencia"
              @input="item.CODIGO_CENTRO=null;selectedAccount=null; item.CODIGO_CUENTA=null"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          md="12"
          xs="12"
          lg="6"
        >
          <b-form-group
            label="Centro de Costos"
            label-for="costCenters"
          >
            <v-select
              id="costCenters"
              v-model="item.CODIGO_CENTRO"
              label="label"
              :options="filteredCC"
              :reduce="option => option.idCentro"
              @input="selectedCenter => {filterAccounts(selectedCenter);selectedAccount=null; item.CODIGO_CUENTA=null}"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          md="12"
          xs="12"
          lg="6"
        >

          <b-form-group
            label="Cuenta Contable"
            label-for="cuentaContable"
          >
            <v-select
              id="code"
              v-model="selectedAccount"
              label="cuenta"
              :options="accountsFiltered"
              :reduce="option => option.idCuenta"
              @input="value => item.CODIGO_CUENTA = value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          md="12"
          xs="12"
          lg="6"
        >
          <b-form-group
            label="Estatus"
            label-for="status"
          >
            <v-select
              id="bankOrigin"
              v-model="item.STATUS"
              label="status"
              :options="statuses"
              :reduce="option => option.idStatus"
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="loading"
          >
            <span v-if="!loading">Guardar</span>
            <span v-if="loading">Un momento...</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            to="/cda/admin/inter-bank-homologation"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card-code>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BAlert
  } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code'
import environment from '@/environment'

export default {
  name: 'CentersTypesAddVue',
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BAlert,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      accounts: [],
      accountsFiltered: [],
      item: {},
      selectedManagementDest: '',
      selectedCostCenters: '',
      selectedAccount: '',
      filteredCC: [],
      loading: true,
      errorMessage: '',
      sourceBank: [{title: 'Banco 02', value: 2}],
      sourceManagement: [{title: 'Gerencia Externa', value: 777}],
      targetBank: [{title: 'Banco 1', value: 1}],
      targetManagement: [{title: 'Gerencia Directiva - Banco 2', value: '888'}],
      costCenters: [],
      gerenciasDirectivas: [],
      statuses: [],
    }
  },
  computed: {

  },
  watch: {
    selectedManagementDest(val) {
      if (val){
        console.log('selectedManagementDest: ', val)
        this.filteredCostCenters(val)
      }
    },
  },
  created() {
    this.catalogLoad()

  },
  methods: {
    async catalogLoad() {
      const dataAccounts = await this.$http.get(`${environment.uri}/accounts/allx`)
      this.accounts = dataAccounts.data
      const costCenters = await this.$http.get(`${environment.uri}/ccenters/all`)
      this.costCenters = costCenters.data
      const dataGerencia = await this.$http.get(`${environment.uri}/mngmts/all`)
      this.gerenciasDirectivas = dataGerencia.data
      this.statuses = this.$store.getters['catalog/allStatuses']

      try {
        const resp = await this.$http.get(`${environment.uri}/interbank/id/${this.$route.params.id}`)
        this.item = resp.data
        this.filterAccounts(this.item.CODIGO_CENTRO_ORIGEN)
        this.selectedAccount = this.accounts.find(acc => acc.idCuenta == this.item.CODIGO_CUENTA)
        this.selectedManagementDest = this.item.CODIGO_GERENCIA
        this.selectedCostCenters = this.item.CODIGO_CENTRO_ORIGEN
        this.filteredCostCenters(this.item.CODIGO_GERENCIA)

      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },

    filteredCostCenters(e) {
      this.filteredCC = this.costCenters.filter(field => field.idGerencia === e)
    },

    async filterAccounts(selectedCenter) {
      this.accountsFiltered = this.accounts.filter(acc => acc.idCentro === selectedCenter)
    },

    async updateHomolgacionBank() {
      this.loading = true
      this.errorMessage = ''
      this.$http.put(`${environment.uri}/interbank/update/id/${this.$route.params.id}`,
          {
            "sourceBank": this.item.CODIGO_BANCO_ORIGEN,
            "sourceManagement": this.item.CODIGO_GERENCIA_ORIGEN,
            "sourceCenter": this.item.CODIGO_CENTRO_ORIGEN,
            "sourceAccount": this.item.CODIGO_CUENTA_ORIGEN,
            "targetBank": this.item.CODIGO_BANCO,
            "targetManagement": this.selectedManagementDest,
            "targetCenter": this.item.CODIGO_CENTRO,
            "targetAccount": this.selectedAccount,
            "status": this.item.STATUS
          }
      )
          .then(() => {
            this.loading = false
            this.$router.replace('/cda/admin/inter-bank-homologation')
          })
          .catch(error => {
            console.error(error)
            this.errorMessage = error.response.data.message
            this.loading = false
          })
    }

  },
}
</script>

<style scoped>

</style>
